import * as React from 'react'
import { BlazeInputNumber } from '../../../../../components/Input/BlazeInputNumber'
import { CreateChildProduct, CreateProduct } from '../ProductStore'
import { isNil } from 'lodash'
import { Button } from 'antd-v4'

interface Props {
  showMaxMin: boolean
  disabled: boolean
  className?: string
  onChange: (key: string, value: number | null, cannabinoidName: string) => void
  max: string | null | undefined
  min: string | null | undefined
  cannabinoid: string | null | undefined
  product: CreateProduct | CreateChildProduct
  cannabinoidName: string
}

export const CannabinoidInputFields = (props: Props) => {
  const handleChange = (key: string, value: number | undefined, cannabinoidName: string) => {
    props.onChange(key, value === undefined ? null : value, cannabinoidName)
    props.product.changed = true
  }

  const clearSelected = async () => {
    handleChange('min', undefined, props.cannabinoidName)
    handleChange('max', undefined, props.cannabinoidName)
  }
  return (
    <div className='d-flex flex-row flex-wrap'>
      {props.showMaxMin ? (
        <div>
          <div style={{ alignItems: 'end' }} className={'d-flex flex-row flex-wrap'}>
            <div className={props.className}>
              <label>Min</label>
              <BlazeInputNumber
                className='d-flex justify-content-center'
                isEditable={!props.disabled}
                value={props.min ? +props.min : null}
                min={0}
                onChange={(value) => {
                  handleChange('min', value, props.cannabinoidName)
                }}
              />
            </div>

            <div className={props.className}>
              <label>Max</label>
              <BlazeInputNumber
                className='d-flex justify-content-center'
                isEditable={!props.disabled}
                value={props.max ? +props.max : null}
                min={0}
                onChange={(value) => {
                  handleChange('max', value, props.cannabinoidName)
                }}
              />
            </div>
            <div className={props.className}>
              <Button onClick={clearSelected}>Reset</Button>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className={props.className}>
            <BlazeInputNumber
              className='d-flex justify-content-center'
              isEditable={!props.disabled}
              value={props.cannabinoid ? +props.cannabinoid : null}
              min={0}
              onChange={(value) => {
                handleChange('', value, props.cannabinoidName)
              }}
            />
          </div>
        </div>
      )}
    </div>
  )
}
