import * as React from 'react'
import { BlazeInputNumber } from '../../../../../components/Input/BlazeInputNumber'
import { CreateChildProduct, CreateProduct, MetaDataReservedKeys } from '../ProductStore'
import { isNil } from 'lodash'
import { ThcCbdInputFieldsHelper } from './ThcCbdInputFieldsHelper'

interface Props {
  showMaxMin: boolean
  disabled: boolean
  className?: string
  onChange: (key: MetaDataReservedKeys, value: number | null) => void
  minTHC: number | null
  thc: number | null
  maxTHC: number | null
  minCBD: number | null
  cbd: number | null
  maxCBD: number | null
  product: CreateProduct | CreateChildProduct
}

export const ThcCbdInputFields = (props: Props) => {
  const { minCBD, maxCBD, minTHC, maxTHC } = props

  const handleChange = (key: MetaDataReservedKeys, value: number | undefined) => {
    props.onChange(key, value === undefined ? null : value)
    props.product!.changed = true
  }

  return (
    <div className='d-flex flex-row flex-wrap'>
      {props.showMaxMin ? (
        <>
          <div className={props.className}>
            <label>Min THC</label>
            <BlazeInputNumber
              className='d-flex justify-content-center'
              isEditable={!props.disabled}
              value={props.minTHC}
              min={0}
              onChange={(value) => {
                ThcCbdInputFieldsHelper.handleMinChange({
                  maxValue: maxTHC,
                  cannabinoidType: 'THC',
                  value,
                  handleChange,
                })
              }}
              onBlur={() => {}}
              placeholder={props.product.metaData.minTHC?.toString()}
            />
          </div>

          <div className={props.className}>
            <label>Max THC</label>
            <BlazeInputNumber
              className='d-flex justify-content-center'
              isEditable={!props.disabled}
              value={props.maxTHC}
              min={0}
              onChange={(value) => {
                ThcCbdInputFieldsHelper.handleMaxChange({
                  minValue: minTHC,
                  cannabinoidType: 'THC',
                  value,
                  handleChange,
                })
              }}
              onBlur={() => {}}
              placeholder={props.product.metaData.maxTHC?.toString()}
            />
          </div>

          <div className={props.className}>
            <label>Min CBD</label>
            <BlazeInputNumber
              className='d-flex justify-content-center'
              isEditable={!props.disabled}
              value={props.minCBD}
              min={0}
              onChange={(value) => {
                ThcCbdInputFieldsHelper.handleMinChange({
                  maxValue: maxCBD,
                  cannabinoidType: 'CBD',
                  value,
                  handleChange,
                })
              }}
              onBlur={() => {}}
              placeholder={props.product.metaData.minCBD?.toString()}
            />
          </div>

          <div className={props.className}>
            <label>Max CBD</label>
            <BlazeInputNumber
              className='d-flex justify-content-center'
              isEditable={!props.disabled}
              value={props.maxCBD}
              min={0}
              onChange={(value) => {
                ThcCbdInputFieldsHelper.handleMaxChange({
                  minValue: minCBD,
                  cannabinoidType: 'CBD',
                  value,
                  handleChange,
                })
              }}
              onBlur={() => {}}
              placeholder={props.product.metaData.maxCBD?.toString()}
            />
          </div>
        </>
      ) : (
        <>
          <div className={props.className}>
            <label>THC</label>
            <BlazeInputNumber
              className='d-flex justify-content-center'
              isEditable={!props.disabled}
              value={props.thc}
              min={0}
              onChange={(value) => {
                handleChange(MetaDataReservedKeys.THC, value)
              }}
              onBlur={() => {}}
              placeholder={props.product.metaData.thc?.toString()}
            />
          </div>

          <div className={props.className}>
            <label>CBD</label>
            <BlazeInputNumber
              className='d-flex justify-content-center'
              isEditable={!props.disabled}
              value={props.cbd}
              placeholder={props.product.metaData.cbd?.toString()}
              min={0}
              onChange={(value) => {
                handleChange(MetaDataReservedKeys.CBD, value)
              }}
              onBlur={() => {}}
            />
          </div>
        </>
      )}
    </div>
  )
}
