import React, { DetailedHTMLProps, HTMLAttributes, ReactElement, PropsWithChildren } from 'react'
import classnames from 'classnames'
import { Col, Row } from 'antd'
import { RowProps } from 'antd/lib/row'
import { ColProps } from 'antd/lib/col'

interface Props {
  labelText: ReactElement
  rowProps?: RowProps
  labelProps?: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  labelColProps?: ColProps
  contentColProps?: ColProps
}

export const ResponsiveRow = (props: PropsWithChildren<Props>) => {
  const responsiveRatio = {
    label: {
      xs: 24,
      md: 8,
    },
    content: {
      xs: 24,
      md: 16,
    },
  }

  const labelColProps = {
    ...responsiveRatio['label'],
    ...props.labelColProps,
  }

  const contentColProps = {
    ...responsiveRatio['content'],
    ...props.contentColProps,
  }

  return (
    <Row
      type='flex'
      gutter={[8, 4]}
      {...props.rowProps}
      className={classnames('gr-responsive-row', props.rowProps?.className)}
    >
      <Col {...labelColProps}>
        <div {...props.labelProps} className={classnames('label', props.labelProps?.className)}>
          {props.labelText}
        </div>
      </Col>

      <Col {...contentColProps}>{props.children}</Col>
    </Row>
  )
}
