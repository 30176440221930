import * as React from 'react'
import { Row, Button, message, List } from 'antd-v4'
import { axios } from '@getgreenline/shared'
import { observable, action, computed } from 'mobx'
import { plainToClass } from 'class-transformer'
import 'react-phone-input-2/lib/plain.css'
import { startsWith } from 'lodash'
import { ContactItem } from './ContactItem'
import { parseErrorMsg } from '../../../utilities/helpers'
import { observer } from 'mobx-react'
import { v4 as uuidv4 } from 'uuid'
import { ModalForm } from './ModalForm'

export interface IContactContract {
  id: string
  name: string
  phone: string
  phoneVerified: boolean
  email?: string
}

export class NewContact implements Omit<IContactContract, 'id' | 'phoneVerified'> {
  @observable
  name: string

  @observable
  phone: string

  @observable
  email?: string

  @observable
  isNewPhoneValid: boolean

  @action
  setName = (name: string) => (this.name = name)

  @action
  setPhone = (phone: string) => (this.phone = phone)

  @action
  setEmail = (email: string) => (this.email = email)

  @action
  setIsNewPhoneValid = (isNewPhoneValid: boolean) => (this.isNewPhoneValid = isNewPhoneValid)

  @computed
  get canSave() {
    return !!this.name && !!this.phone && this.isNewPhoneValid
  }

  @computed
  get newContact(): IContactContract {
    return {
      id: uuidv4(),
      name: this.name,
      phone: this.phone,
      phoneVerified: false,
      email: this.email,
    }
  }
}

export class Contact extends NewContact {
  private initialContact: IContactContract

  id: string

  phoneVerified: boolean

  @observable
  code: string | undefined

  @action
  setCode = (code: string) => (this.code = code)

  @computed
  get isPhoneChanged() {
    return this.initialContact.phone !== this.phone
  }
  @computed
  get shouldVerifyPhone(): boolean {
    return this.isNewPhoneValid
  }

  @computed
  get updatedContact(): IContactContract {
    return {
      id: this.initialContact.id,
      name: this.name,
      phone: this.phone,
      phoneVerified: this.initialContact.phoneVerified,
      email: this.email,
    }
  }
}

interface State {
  isModalVisible: boolean
  isLoading: boolean
  contacts: Contact[]
}

@observer
export class InternalSection extends React.Component<{}, State> {
  URL = `${process.env.REACT_APP_ALARM_CONTACT_API_URL}/api/v1/alarm-contacts`

  newContact = plainToClass(NewContact, {
    name: '',
    phone: '+1',
  })

  constructor(props: {}) {
    super(props)

    this.state = {
      isModalVisible: false,
      isLoading: false,
      contacts: [],
    }
  }

  componentDidMount = async () => {
    await this.getContacts()
  }

  getContacts = async () => {
    try {
      this.setState({ isLoading: true })
      const res = await axios.get<IContactContract[]>(this.URL)

      const contacts = res.data.map((contact) => {
        return plainToClass(Contact, {
          ...contact,
          initialContact: contact,
        })
      })

      this.setState({ contacts })
    } catch (error) {
      message.error(parseErrorMsg(error))
    } finally {
      this.setState({ isLoading: false })
    }
  }

  isValid = (inputNumber: string, country: object, contact: NewContact | Contact) => {
    const isDialCodeValid = startsWith(inputNumber, country['dialCode'])

    const fullDigitCount = country['format'].match(/\./g).length

    const phoneNumberCount = inputNumber.toString().length

    const isValid = isDialCodeValid && fullDigitCount === phoneNumberCount

    contact.setIsNewPhoneValid(isValid)

    return isValid
  }

  render() {
    return (
      <Row className='d-flex justify-content-center align-items-center h-100' style={{ flex: 1 }}>
        <Row className='p-4' style={{ backgroundColor: 'var(--white)' }}>
          <Row>
            <Button onClick={() => this.setState({ isModalVisible: true })}>Add contact</Button>
          </Row>

          <List
            itemLayout='horizontal'
            dataSource={this.state.contacts}
            loading={this.state.isLoading}
            renderItem={(contact, index) => (
              <ContactItem
                index={index}
                contact={contact}
                getContacts={this.getContacts}
                isValid={this.isValid}
              />
            )}
          />
        </Row>

        <ModalForm
          visible={this.state.isModalVisible}
          getContacts={this.getContacts}
          onClose={() => this.setState({ isModalVisible: false })}
          isValid={this.isValid}
        />
      </Row>
    )
  }
}
