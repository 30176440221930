import * as React from 'react'
import { Modal, Input, message } from 'antd'
import { ResponsiveRow } from '../../../components/ResponsiveContainer/ResponsiveRow'
import PhoneInput from 'react-phone-input-2'
import { plainToClass } from 'class-transformer'
import { NewContact, Contact, IContactContract } from './InternalSection'
import { axios } from '@getgreenline/shared'
import { observer } from 'mobx-react'

interface Props {
  visible: boolean
  getContacts: () => void
  onClose: () => void
  isValid: (inputNumber: string, country: object, contact: NewContact | Contact) => boolean
}

interface State {
  isSaving: boolean
  isCodeCreating: boolean
  isCodeValidating: boolean
  submittedContact?: Contact
}

@observer
export class ModalForm extends React.Component<Props, State> {
  KEY = 'MODAL_MESSAGE'

  URL = `${process.env.REACT_APP_ALARM_CONTACT_API_URL}/api/v1/alarm-contacts`

  newContact = plainToClass(NewContact, {
    name: '',
    phone: '+1',
  })

  constructor(props: Props) {
    super(props)

    this.state = {
      isSaving: false,
      isCodeCreating: false,
      isCodeValidating: false,
      submittedContact: undefined,
    }
  }

  resetNewContact = () => {
    this.newContact = plainToClass(NewContact, {
      name: '',
      phone: '+1',
    })
  }

  createContact = async () => {
    try {
      this.setState({ isSaving: true })

      const { newContact } = this.newContact

      const res = await axios.post<IContactContract>(`${this.URL}`, newContact)

      message.success({
        key: this.KEY,
        content: 'Contact created! Please stand by...',
      })

      const submittedContact = plainToClass(Contact, res.data)

      await this.setState({ submittedContact })

      await this.createCode(submittedContact)

      await this.props.getContacts()
    } catch (error) {
      message.error('Could not create contact')
    } finally {
      this.setState({ isSaving: false })
    }
  }

  createCode = async (contact: Contact) => {
    try {
      this.setState({ isCodeCreating: true })

      await axios.post(`${this.URL}/${contact.id}/code`, contact)

      message.success({
        key: this.KEY,
        content: `Code sent to ${contact.phone}. Please validate the code`,
      })
    } catch (error) {
      message.error({
        key: this.KEY,
        content: `Failed to generate verification code`,
      })
    } finally {
      this.setState({ isCodeCreating: false })
    }
  }

  validateCode = async (contact: Contact) => {
    try {
      this.setState({ isCodeValidating: true })
      const res = await axios.get<IContactContract>(
        `${this.URL}/${contact.id}/code/${contact.code}`,
      )

      await this.props.getContacts()

      message.success(`Code verified. Please validate the code.`)
    } catch (error) {
      message.error('Validation failed')
    } finally {
      this.setState({ isCodeValidating: false })
    }
  }

  onOk = async () => {
    const { submittedContact } = this.state

    if (submittedContact) {
      await this.validateCode(submittedContact)
      this.props.onClose()
    } else {
      await this.createContact()
    }
  }

  get loading() {
    return this.state.submittedContact ? this.state.isCodeValidating : this.state.isSaving
  }

  render() {
    const { submittedContact } = this.state

    return (
      <Modal
        title='Add contact'
        visible={this.props.visible}
        onOk={this.onOk}
        okButtonProps={{
          loading: this.loading,
          disabled: this.loading,
        }}
        okText={submittedContact ? 'Validate' : 'Submit'}
        onCancel={() => {
          const confirmed = window.confirm('Are you sure?')

          if (!confirmed) return

          this.resetNewContact()
          this.props.onClose()
        }}
      >
        <ResponsiveRow
          labelText={
            <div>
              <span className='mr-2 text-danger'>*</span>
              <strong>
                <span>Name</span>
              </strong>
            </div>
          }
        >
          <Input
            value={this.newContact.name}
            placeholder='Add name'
            onChange={(e) => this.newContact.setName(e.target.value)}
          />
        </ResponsiveRow>

        <ResponsiveRow
          labelText={
            <div>
              <span className='mr-2 text-danger'>*</span>
              <strong>
                <span>Phone</span>
              </strong>
            </div>
          }
          rowProps={{ style: { marginTop: 16 } }}
        >
          <PhoneInput
            enableSearch
            countryCodeEditable={false}
            country='ca'
            preferredCountries={['ca', 'us']}
            inputStyle={{ width: '100%' }}
            searchStyle={{ width: '90%' }}
            value={this.newContact.phone}
            onChange={(value, data, e, formattedValue) => {
              this.newContact.setPhone(formattedValue)
            }}
            isValid={(inputNumber, country) => {
              return this.props.isValid(inputNumber, country, this.newContact)
            }}
          />
        </ResponsiveRow>

        {submittedContact && (
          <ResponsiveRow
            labelText={
              <div>
                <span className='mr-2 text-danger'>*</span>
                <strong>
                  <span>Verification</span>
                </strong>
              </div>
            }
            rowProps={{ style: { marginTop: 16 } }}
          >
            <Input
              value={submittedContact.code}
              placeholder='Enter verification code'
              onChange={(e) => submittedContact.setCode(e.target.value)}
            />
          </ResponsiveRow>
        )}
      </Modal>
    )
  }
}
