import React, { Component } from 'react'
import { InputNumber } from 'antd'
import { GrPrice, removeDecimalPoint } from '../../utilities/helpers'
import { InputNumberProps } from 'antd/lib/input-number'
import { isNumber, isNil } from 'lodash'

interface Props extends Omit<InputNumberProps, 'value'> {
  className?: string
  isEditable: boolean
  value: number | null
  removeDecimalPoints?: boolean
}

export class BlazeInputNumber extends Component<Props> {
  validateValue = (value?: string | number): string => {
    const { min, max, removeDecimalPoints } = this.props

    const numericValue = Number(`${value}`)

    if (isNaN(numericValue)) {
      return ''
    }

    if (min !== undefined && numericValue < min) {
      return `${min}`
    }

    if (max !== undefined && numericValue > max) {
      return `${max}`
    }

    if (removeDecimalPoints) {
      if (!Number.isInteger(numericValue)) {
        return ''
      }
    }

    return `${value}`
  }

  defaultFormatter = (value: number | string | undefined) => {
    const validatedValue = this.validateValue(value)

    const val = this.props.removeDecimalPoints ? removeDecimalPoint(validatedValue) : validatedValue

    return `${val}`
  }

  render() {
    const value = isNumber(this.props.value) ? this.props.value : undefined

    switch (true) {
      case this.props.isEditable:
        return (
          <InputNumber
            {...this.props}
            value={value}
            formatter={this.props.formatter || this.defaultFormatter}
            onChange={(value) => {
              const validatedValue = this.validateValue(value)

              const val = this.props.removeDecimalPoints
                ? removeDecimalPoint(validatedValue)
                : Number(validatedValue)

              this.props.onChange?.(val)
            }}
          />
        )
      case !value:
        return <div className={this.props.className}>-</div>
      default:
        return (
          <div className={this.props.className}>
            {GrPrice.convertDecimalString(value || 0, 0, 2)}
          </div>
        )
    }
  }
}
