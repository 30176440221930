import * as React from 'react'
import { axios } from '@getgreenline/shared'
import { Row, Button, Input, List } from 'antd-v4'
import { Col, Avatar, message, Badge, Icon, Tooltip } from 'antd'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/plain.css'
import { Contact, IContactContract } from './InternalSection'
import { observer } from 'mobx-react'
import { parseErrorMsg } from '../../../utilities/helpers'

interface Props {
  index: number
  contact: Contact
  getContacts: () => void
  isValid: (inputNumber: string, country: object, contact: Contact) => boolean
}

interface State {
  isUpdating: boolean
  isDeleting: boolean
  isCodeCreating: boolean
  isCodeValidating: boolean
  isPhoneValid: boolean
  showCodeInput: boolean
}

@observer
export class ContactItem extends React.Component<Props, State> {
  URL = `${process.env.REACT_APP_ALARM_CONTACT_API_URL}/api/v1/alarm-contacts`

  constructor(props: Props) {
    super(props)

    this.state = {
      isUpdating: false,
      isDeleting: false,
      isCodeCreating: false,
      isCodeValidating: false,
      isPhoneValid: false,
      showCodeInput: false,
    }
  }

  updateContact = async (index: number, contact: Contact) => {
    try {
      this.setState({ isUpdating: true })

      const { updatedContact } = contact

      const res = await axios.put<IContactContract>(
        `${this.URL}/${updatedContact.id}`,
        updatedContact,
      )
      message.success('Updating successful')

      await this.props.getContacts()
    } catch (error) {
      message.error(parseErrorMsg(error))
    } finally {
      this.setState({ isUpdating: false })
    }
  }

  removeContact = async (index: number, contact: Contact) => {
    try {
      this.setState({ isDeleting: true })

      await axios.delete(`${this.URL}/${contact.id}`)

      message.success('Deleting successful')

      await this.props.getContacts()
    } catch (error) {
      message.error('Deleting failed')
    } finally {
      this.setState({ isDeleting: false })
    }
  }

  createCode = async (contact: Contact) => {
    try {
      this.setState({ isCodeCreating: true })
      await axios.post(`${this.URL}/${contact.id}/code`, contact.updatedContact)

      this.setState({ showCodeInput: true })

      message.success(`Code sent to ${contact.phone}. Please validate the code.`)
    } catch (error) {
      message.error(parseErrorMsg(error))
    } finally {
      this.setState({ isCodeCreating: false })
    }
  }

  validateCode = async (index: number, contact: Contact) => {
    try {
      this.setState({ isCodeValidating: true })
      const res = await axios.get<IContactContract>(
        `${this.URL}/${contact.id}/code/${contact.code}`,
      )

      this.setState({ showCodeInput: false })

      message.success(`Code verified. Please validate the code.`)

      await this.props.getContacts()
    } catch (error) {
      message.error(parseErrorMsg(error))
    } finally {
      this.setState({ isCodeValidating: false })
    }
  }

  render() {
    const { index, contact } = this.props

    return (
      <List.Item {...this.props} style={{ paddingTop: 35 }}>
        <List.Item.Meta
          avatar={
            <Tooltip
              title={contact.phoneVerified ? 'Phone verified' : 'Require phone verification'}
            >
              <Badge status={contact.phoneVerified ? 'success' : 'error'}>
                <Avatar src='https://joeschmoe.io/api/v1/random' />
              </Badge>
            </Tooltip>
          }
          title={
            <Row className='d-flex'>
              <Col flex='auto' className='mr-2'>
                <Input value={contact.name} onChange={(e) => contact.setName(e.target.value)} />
              </Col>
              <Col flex='none'>
                <Button
                  type='primary'
                  className='mr-2'
                  disabled={this.state.isUpdating}
                  onClick={async () => this.updateContact(index, contact)}
                >
                  <Icon type={this.state.isUpdating ? 'loading' : 'save'} />
                </Button>

                <Button
                  type='ghost'
                  disabled={this.state.isDeleting}
                  onClick={async () => this.removeContact(index, contact)}
                >
                  <Icon type={this.state.isDeleting ? 'loading' : 'close'} />
                </Button>
              </Col>
            </Row>
          }
          description={
            <>
              <Row className='d-flex'>
                <Col flex={1} className='mr-2'>
                  <PhoneInput
                    enableSearch
                    countryCodeEditable={false}
                    preferredCountries={['ca', 'us']}
                    inputStyle={{ width: '100%' }}
                    searchStyle={{ width: '90%' }}
                    value={contact.phone}
                    onChange={(value, data, e, formattedValue) => {
                      contact.setPhone(formattedValue)
                    }}
                    isValid={(inputNumber, country) => {
                      return this.props.isValid(inputNumber, country, contact)
                    }}
                  />
                </Col>
                {contact.shouldVerifyPhone && (
                  <Col flex='none' className='d-flex align-items-center'>
                    <Button
                      disabled={this.state.isCodeCreating}
                      onClick={async () => this.createCode(contact)}
                    >
                      {this.state.isCodeCreating ? <Icon type='loading' /> : 'Verify'}
                    </Button>
                  </Col>
                )}
              </Row>

              {this.state.showCodeInput && (
                <Row className='d-flex mt-1'>
                  <Col flex='auto' className='mr-2'>
                    <Input
                      value={contact.code}
                      placeholder='Enter verification code'
                      onChange={(e) => contact.setCode(e.target.value)}
                    />
                  </Col>
                  <Col flex='none'>
                    <Button
                      disabled={this.state.isCodeValidating}
                      onClick={async () => this.validateCode(index, contact)}
                    >
                      {this.state.isCodeValidating ? <Icon type='loading' /> : 'Validate'}
                    </Button>
                  </Col>
                </Row>
              )}
            </>
          }
        />
      </List.Item>
    )
  }
}
